<template>
  <div>
    <div class="row">
      <div class="col-md-3" id="solicitacoes">
        <router-link to="/solicitacoes" style="color: #82868b">
          <b-card no-body>
            <b-card-body class="pb-0">
              <b-avatar class="mb-1" :variant="`light-warning`" size="45">
                <feather-icon size="21" :icon="'HeartIcon'" />
              </b-avatar>
              <div class="truncate mb-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ dashboard.highlights.solicitations }}
                </h2>
                <span>Solicitações Cadastradas</span>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </div>
      <div
        class="col-md-3"
        id="aprovacoes"
        v-if="$store.state.user.perfil_id != 22"
      >
        <router-link to="/aprovacoes" style="color: #82868b">
          <b-card no-body>
            <b-card-body class="pb-0">
              <b-avatar class="mb-1" :variant="`light-danger`" size="45">
                <feather-icon size="21" :icon="'CheckSquareIcon'" />
              </b-avatar>
              <div class="truncate mb-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ dashboard.highlights.in_approve }}
                </h2>
                <span>Aguardando Aprovação</span>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </div>
      <div
        class="col-md-3"
        id="programacoes"
        v-if="$store.state.user.perfil_id != 22"
      >
        <router-link to="/programacoes" style="color: #82868b">
          <b-card no-body>
            <b-card-body class="pb-0">
              <b-avatar class="mb-1" :variant="`light-primary`" size="45">
                <feather-icon size="21" :icon="'CalendarIcon'" />
              </b-avatar>
              <div class="truncate mb-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ dashboard.highlights.waiting_programming }}
                </h2>
                <span>Aguardando Programação</span>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </div>
      <div
        class="col-md-3"
        id="usuarios"
        v-if="$store.state.user.perfil_id != 22"
      >
        <router-link to="/cancelados_finalizados" style="color: #82868b">
          <b-card no-body>
            <b-card-body class="pb-0">
              <b-avatar class="mb-1" :variant="`light-success`" size="45">
                <i class="fas fa-check" style="font-size: 21px"></i>
              </b-avatar>
              <div class="truncate mb-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ dashboard.highlights.solicitations_f }}
                </h2>
                <span>Solicitações Finalizadas</span>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </div>
      <div
        class="col-md-3"
        id="usuarios"
        v-if="$store.state.user.perfil_id != 22"
      >
        <router-link to="/users" style="color: #82868b">
          <b-card no-body>
            <b-card-body class="pb-0">
              <b-avatar class="mb-1" :variant="`light-success`" size="45">
                <feather-icon size="21" :icon="'UsersIcon'" />
              </b-avatar>
              <div class="truncate mb-2">
                <h2 class="mb-25 font-weight-bolder">
                  {{ dashboard.highlights.users }}
                </h2>
                <span>Usuários Cadastrados</span>
              </div>
            </b-card-body>
          </b-card>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      interval: "",
      dashboard: {
        highlights: {
          users: 0,
          solicitations: 0,
          in_approve: 0,
          waiting_programming: 0,
          solicitations_f: 0,
        },
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "dashboard";

      axios
        .post(api)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  destroyed() {
    clearInterval(this.interval);
    console.log("destroyed");
  },
  mounted() {
    const self = this;

    self.getDashboard();

    let intervl = 0;

    setTimeout(function () {
      while (intervl < 1000) {
        clearInterval(intervl);
        console.log("limpando intervals");
        intervl++;
      }
    }, 1000);

    if (self.interval == "") {
      self.interval = setInterval(function () {
        self.getDashboard();
      }, 10000);
    }

    // Echo.private("user.1").listen(".Alert", (data) => {
    //   console.log(data);
    // });
  },
  created() {},
};
</script>

<style></style>
